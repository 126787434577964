<template>
  <div>
    <navbar></navbar>

    <v-container fluid class="mt-4 mb-4">
      <v-row class="alert mt-4">
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="error_msg != null">
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="success_msg != null">
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <v-row class="d-flex text-main justify-center">
        <v-col cols="11">
          <v-data-table :headers="headers" :items="companyinvoices" :loading="loading" :search="search"
            :sort-desc="true" sort-by="id" class="elevation-1 nzar-font radius-15 mode" :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': langkeyword('PERPAGE'),
              'items-per-page-options': perPages(),
            }" :items-per-page="10">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><v-icon>mdi-shopping</v-icon>
                  {{ langkeyword("COMPANYINVOICES") }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="1000px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success"
                      :style="permissionExist('#add_companyinvoices') ? 'color: white;float:right' : 'display:none;'"
                      class="mb-2 radius-5 nzar-font" v-bind="attrs" v-on="on">
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("ADD") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode">
                    <v-card-title>
                      <span class="nzar-font" v-if="isAdd() === true"><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span>
                      <span class="nzar-font" v-if="isEdit() === true"><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span>
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form>
                          <v-select v-if="isAdd() === true || isEdit() === true" append-icon="mdi-store"
                            v-model="companyinvoicesObject.store_id" :items="stores"
                            :item-text="langoptions('value') + '_name'" item-value="id" :label="langkeyword('STORE')"
                            :class="isNormal() ? 'd-none' : ''" dense></v-select>
                          <v-select v-if="isAdd() === true || isEdit() === true" append-icon="mdi-cash"
                            v-model="companyinvoicesObject.treasure_id" :items="treasures"
                            :item-text="langoptions('value') + '_name'" item-value="id" :label="langkeyword('TREASURE')"
                            dense></v-select>
                          <v-text-field type="number" min="0" step="any" class="nzar-font"
                            v-model="companyinvoicesObject.invoicenumber" :label="langkeyword('INVOICENUMBER')">
                          </v-text-field>
                          <v-text-field type="date" class="nzar-font" v-model="companyinvoicesObject.date"
                            :label="langkeyword('DATE')">
                          </v-text-field>
                          <v-text-field class="nzar-font" append-icon="mdi-format-title"
                            v-model="companyinvoicesObject.note" :label="langkeyword('NOTE')">
                          </v-text-field>

                          <v-select v-if="isAdd() === true || isEdit() === true"
                            v-model="companyinvoicesObject.currency_id" :items="currencies"
                            :item-text="langoptions('value') + '_name'" item-value="id" :label="langkeyword('CURRENCY')"
                            dense></v-select>

                          <v-autocomplete v-if="isAdd() === true || isEdit() === true"
                            v-model="companyinvoicesObject.company_id" :items="companies" item-text="name"
                            item-value="id" :label="langkeyword('COMPANY')" dense></v-autocomplete>

                          <v-select v-if="isAdd() === true || isEdit() === true"
                            v-model="companyinvoicesObject.invoicetype_id" :items="invoicetypes"
                            :item-text="langoptions('value') + '_name'" item-value="id"
                            :label="langkeyword('INVOICETYPE')" dense></v-select>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="bg-main white--text" v-if="isAdd() === true" dark @click="saveCompanyInvoices">
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn color="orange darken-1" v-if="isEdit() === true" dark
                        @click="updateCompanyInvoices(companyinvoicesObject.id)">
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="red lighten-2" dark @click="deleteCompanyInvoices(companyinvoicesObject.id)">
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field v-if="permissionExist('#search_companyinvoices')" class="nzar-font" v-model="search"
                      append-icon="mdi-magnify" :label="langkeyword('SEARCH')" hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-slot:[`item.shipping`]="{ item }">
              <h4>
                <v-btn color="warning" class="white--text"
                  v-if="item.shipping == 'ontheway'"><v-icon>mdi-truck-delivery</v-icon> {{ langkeyword('ONTHEWAY')
                  }}</v-btn>
                <v-btn color="success" class="white--text"
                  v-if="item.shipping == 'delivered'"><v-icon>mdi-truck-check</v-icon> {{
                    langkeyword('DELIVERED')
                  }}</v-btn>
              </h4>
            </template>


            <template v-slot:[`item.total`]="{ item }">
              {{
                item.currency_id === 1 ?
                  dinar((item.invoicetotal - item.discount) + item.driverwage) :
                  dollar((item.invoicetotal - item.discount) + item.driverwage)
              }}
            </template>

            <!--  -->
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon v-if="permissionExist('#edit_companyinvoices')" small
                class="mr-2 orange white--text px-2 py-2 rounded" @click="editDialog(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon v-if="permissionExist('#view_companyinvoices')" small
                class="mr-2 blue white--text px-2 py-2 rounded" @click="viewInvoice(item)">
                mdi-eye
              </v-icon>
              <v-icon v-if="permissionExist('#delete_companyinvoices')" small
                class="mr-2 red white--text px-2 py-2 rounded" @click="deleteDialog(item)">
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row v-if="permissionExist('#search_companies')">
        <v-col cols="11" class="ma-auto">
          <v-expansion-panels popout hover focusable>
            <v-expansion-panel>
              <v-expansion-panel-header class="mode" expand-icon="mdi-domain">
                {{ langkeyword("COMPANIES") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mode">
                <companies :shownavandfooter="false" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>

<script>
import Vue from "vue";
import CompanyInvoicesRequests from "../../../requests/dashboard/buy/CompanyInvoices";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
import companies from "../buy/companies.vue";
export default {
  components: {
    navbar,
    dashboardFooter,
    companies,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    snackbar: true,
    companyinvoices: [],
    search: "",
    loading:
      Vue.prototype.getters().getcompanyinvoices == null ||
        Vue.prototype.getters().getcompanyinvoices == "" ||
        Vue.prototype.getters().getcompanyinvoices == undefined
        ? true
        : false,
    error_msg: null,
    success_msg: null,
    headers: [
      { text: "#", value: "id", align: "center" },
      {
        text: Vue.prototype.langkeyword("INVOICENUMBER"),
        value: "invoicenumber",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("DATE"),
        value: "date",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("COMPANY"),
        value: "companies_name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("INVOICETYPE"),
        value: "invoicetypes_" + Vue.prototype.langoptions("value") + "_name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("CURRENCY"),
        value: "currencies_" + Vue.prototype.langoptions("value") + "_name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("SHIPPING"),
        value: "shipping",
        sortable: false,
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("ALLTOTAL"),
        value: "total",
        sortable: false,
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("ACTIONS"),
        value: "edit",
        sortable: false,
        align: "center",
      },
    ],
    CURRENTFORM: "add",
    companyinvoicesObject: {
      user_id: Vue.prototype.dashboardAuthId(),
      invoicenumber: "",
      date: Vue.prototype.today(),
      note: ".",
      company_id: "",
      invoicetype_id: 1,
      currency_id: 2,
      store_id: Vue.prototype.state().getdashboardauth.store_id,
      treasure_id: '',
    },
  }),
  watch: {
    options: {
      handler() {
        this.readData();
      },
    },
  },
  mounted() {
    this.readData();
  },
  computed: {
    companies() {
      return this.isNormal() ? this.$store.getters.getcompanies.filter(c => { return c.user_id == this.userId() }) : this.$store.getters.getcompanies
    },
    invoicetypes() {
      return this.$store.getters.getinvoicetypes;
    },
    currencies() {
      return this.$store.getters.getcurrencies;
    },
    stores() {
      return this.$store.getters.getstores;
    },
    treasures() {
      return this.$store.getters.gettreasures.filter(t => { return t.store_id == this.companyinvoicesObject.store_id })
    },
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true;
      } else {
        return false;
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true;
      } else {
        return false;
      }
    },
    cleanMessages() {
      this.companyinvoicesObject = { user_id: Vue.prototype.dashboardAuthId() };
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    validate(params, callback) {
      this.error_msg = null;
      params.forEach((param) => {
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED");
        }
      });
      if (this.error_msg == null) {
        return callback();
      }
      this.cleanMessages();
    },
    editDialog(item) {
      this.CURRENTFORM = "edit";
      this.companyinvoicesObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          invoicenumber: "",
          date: Vue.prototype.today(),
          note: ".",
          company_id: "",
          invoicetype_id: 1,
          currency_id: 2,
          store_id: Vue.prototype.state().getdashboardauth.store_id,
          treasure_id: ''
        },
        item
      );
      this.dialog = true;
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock";
      this.companyinvoicesObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          invoicenumber: "",
          date: Vue.prototype.today(),
          note: ".",
          company_id: "",
          invoicetype_id: 1,
          currency_id: 2,
          store_id: Vue.prototype.state().getdashboardauth.store_id,
          treasure_id: ''
        },
        item
      );
      this.dialog = true;
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete";
      this.companyinvoicesObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          invoicenumber: "",
          date: Vue.prototype.today(),
          note: ".",
          company_id: "",
          invoicetype_id: 1,
          currency_id: 2,
          store_id: Vue.prototype.state().getdashboardauth.store_id,
          treasure_id: ''
        },
        item
      );
      this.dialogDelete = true;
    },
    closeMainDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.companyinvoicesObject = Object.assign(
          {
            user_id: Vue.prototype.dashboardAuthId(),
            invoicenumber: "",
            date: Vue.prototype.today(),
            note: ".",
            company_id: "",
            invoicetype_id: 1,
            currency_id: 2,
            store_id: Vue.prototype.state().getdashboardauth.store_id,
            treasure_id: ''
          },
          this.defaultItem
        );
        this.CURRENTFORM = "add";
      });
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.companyinvoicesObject = Object.assign(
          {
            user_id: Vue.prototype.dashboardAuthId(),
            invoicenumber: "",
            date: Vue.prototype.today(),
            note: ".",
            company_id: "",
            invoicetype_id: 1,
            currency_id: 2,
            store_id: Vue.prototype.state().getdashboardauth.store_id,
            treasure_id: ''
          },
          this.defaultItem
        );
        this.CURRENTFORM = "add";
      });
    },
    readData() {
      this.companyinvoices = this.$store.getters.getcompanyinvoices;
      setTimeout(() => {
        this.companyinvoicesObject.store_id = Vue.prototype.state().getdashboardauth.store_id
        this.companyinvoicesObject.treasure_id = this.treasures[0]['id']
        if (!this.permissionExist("#view_companyinvoices")) {
          this.$router.push("/dashboard/home");
        }
      }, 500);
    },
    saveCompanyInvoices() {
      this.validate(
        [
          this.companyinvoicesObject.invoicenumber,
          this.companyinvoicesObject.date,
          this.companyinvoicesObject.note,
          this.companyinvoicesObject.company_id,
          this.companyinvoicesObject.invoicetype_id,
          this.companyinvoicesObject.currency_id,
          this.companyinvoicesObject.store_id,
          this.companyinvoicesObject.treasure_id,
        ],
        () => {
          CompanyInvoicesRequests.add(
            {
              addCompanyInvoices: true,
              invoicenumber: this.companyinvoicesObject.invoicenumber,
              date: this.companyinvoicesObject.date,
              note: this.companyinvoicesObject.note,
              company_id: this.companyinvoicesObject.company_id,
              invoicetype_id: this.companyinvoicesObject.invoicetype_id,
              currency_id: this.companyinvoicesObject.currency_id,
              store_id: this.companyinvoicesObject.store_id,
              treasure_id: this.companyinvoicesObject.treasure_id,
              user_id: Vue.prototype.dashboardAuthId(),
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.companyinvoices = res.data.data;
                this.companyinvoices = res.data.data;
                this.$router.push(
                  "/dashboard/buy/companyinvoices/" + res.data.lastid
                );
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    updateCompanyInvoices(companyinvoiceId) {
      this.validate(
        [
          this.companyinvoicesObject.invoicenumber,
          this.companyinvoicesObject.date,
          this.companyinvoicesObject.note,
          this.companyinvoicesObject.company_id,
          this.companyinvoicesObject.invoicetype_id,
          this.companyinvoicesObject.currency_id,
          this.companyinvoicesObject.store_id,
          this.companyinvoicesObject.treasure_id,
        ],
        () => {
          CompanyInvoicesRequests.edit(
            {
              editCompanyInvoices: true,
              companyinvoice_id: companyinvoiceId,
              invoicenumber: this.companyinvoicesObject.invoicenumber,
              date: this.companyinvoicesObject.date,
              note: this.companyinvoicesObject.note,
              company_id: this.companyinvoicesObject.company_id,
              invoicetype_id: this.companyinvoicesObject.invoicetype_id,
              currency_id: this.companyinvoicesObject.currency_id,
              store_id: this.companyinvoicesObject.store_id,
              treasure_id: this.companyinvoicesObject.treasure_id,
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.companyinvoices = res.data.data;
                this.companyinvoices = res.data.data;
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    deleteCompanyInvoices(companyinvoiceId) {
      CompanyInvoicesRequests.delete(
        { deleteCompanyInvoices: true, companyinvoice_id: companyinvoiceId },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.companyinvoices = res.data.data;
            this.companyinvoices = res.data.data;
          }
        }
      );
      this.cleanMessages();
      this.closeDeleteDialog();
    },
    viewInvoice(item) {
      this.$router.push("/dashboard/buy/companyinvoices/" + item.id);
    },
    printInvoice(item) {
      this.$router.push("/dashboard/buy/companyinvoices/print/" + item.id);
    },
  },
};
</script>
