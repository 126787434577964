<template>
  <div>loading ...</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.$router.push('/dashboard')
      window.location.href = ''
    }, 500);
  },
};
</script>